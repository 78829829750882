<template>
  <b-form-group :label="label">
    <template v-if="label" v-slot:label>
      {{ label }}
      <span v-if="required" class="text-danger"> (*) </span>
    </template>
    <v-select id="type" v-model="type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
      :options="options" placeholder="Loại thống kê" @input="valueChanged">
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template></v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const environment = process.env.VUE_APP_PROVIDER || "development";
    let options;
    if (environment === "vbill" || environment === "development") {
      options = [
        { name: "Khoản thu theo nhóm khách hàng và loại dịch vụ", id: 1 },
        { name: "Khoản thu theo nhóm khách hàng và dịch vụ", id: 2 },
        { name: "Tổng hợp khoản thu", id: 3 },
      ];
    }
    else {
      options = [
        { name: "Khoản thu theo lớp và loại dịch vụ", id: 1 },
        { name: "Khoản thu theo lớp và dịch vụ", id: 2 },
        { name: "Khoản thu theo học sinh và dịch vụ", id: 4 },
        { name: "Tổng hợp khoản thu", id: 3 },
      ];
    }
    return {
      type: this.defaultValue,
      options: options,
    };
  },
  methods: {
    valueChanged() {
      this.$emit("input", this.type);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
</style>
