<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ reportType.name }}</b-card-title>
      <b-button v-b-tooltip.hover.v-success variant="success" class="btn-icon ml-50" title="Xuất dữ liệu"
        @click="exportData">
        <feather-icon icon="DownloadIcon" />
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <select-report-type :label="null" v-model="reportType" :default-value="reportType" />
        </b-col>
        <b-col>
          <select-school :label="null" v-model="school" :default-value="school" />
        </b-col>
        <b-col v-if="reportType.id === 4">
          <select-grade :label="null" v-model="grade" :default-value="grade" :school="school" />
        </b-col>
        <b-col v-if="reportType.id === 4">
          <select-class :label="null" v-model="classObject" :default-value="classObject" :school="school" :grade="grade" />
        </b-col>

        <b-col :cols="reportType.id !== 4 ? 6 : 12">
          <b-row>
            <b-col>
              <select-month v-model="month" :default-value="month" />
            </b-col>

            <b-col>
              <select-date placeholder="Chọn ngày bắt đầu" v-model="startDate"
                :disable="disableStartDate"></select-date>
            </b-col>

            <b-col>
              <select-date placeholder="Chọn ngày kết thúc" v-model="endDate" :disable="disableEndDate"></select-date>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="htmlContent">
        <b-col>
          <div class="overflow-auto" v-html="htmlContent"></div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
  BButton,
  VBModal,
  VBTooltip,
  BFormInput,
} from "bootstrap-vue";
import SelectMonth from "@/views/components/SelectMonth.vue";
import SelectSchool from "@/views/components/SelectSchool.vue";
import useReportByServiceType from "./useReportByServiceType";
import SelectReportType from "@/views/components/SelectReportType.vue";
import SelectDate from "@/views/components/SelectDate.vue";
import { computed } from "@vue/composition-api";
import moment from "moment";
import SelectClass from "@/views/components/SelectClass.vue";
import SelectGrade from "@/views/components/SelectGrade.vue";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BButton,
    BFormInput,
    SelectMonth,
    SelectSchool,
    SelectReportType,
    SelectDate,
    SelectClass,
    SelectGrade
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const {
      school,
      month,
      fetchData,
      htmlContent,
      reportType,
      exportData,
      startDate,
      endDate,
      classObject,
      grade
    } = useReportByServiceType();

    const disableStartDate = computed(() => {
      if (!endDate.value) return [];
      return [
        {
          from: moment(endDate.value, "DD-MM-YYYY")
            .add(1, "day")
            .format("DD/MM/YYYY"),
          to: moment().add(50, "year").format("DD-MM-YYYY"),
        },
      ];
    });

    const disableEndDate = computed(() => {
      if (!startDate.value) return [];
      return [
        {
          from: moment().subtract(20, "year").format("DD/MM/YYYY"),
          to: moment(startDate.value, "DD-MM-YYYY")
            .subtract(1, "day")
            .format("DD/MM/YYYY"),
        },
      ];
    });

    return {
      school,
      classObject,
      grade,
      month,
      fetchData,
      htmlContent,
      reportType,
      exportData,
      startDate,
      endDate,
      disableStartDate,
      disableEndDate,
    };
  },

  created() {
    this.fetchData();
  },
};
</script>
